import request from '@/utils/request'

export default {
  login: (params) => {
    return request({
      url: `/dev-api/eye/api/user/login`,
      method: 'post',
      data: params
    })
  },
  qrcode: (params) => {
    return request({
      url: `/dev-api/eye/api/user/qrcode`,
      method: 'get',
      params: params
    })
  },
  getRes: (params) => {
    return request({
      url: `/dev-api/eye/api/user/scan/result`,
      method: 'get',
      params: params
    })
  },
}