<template>
  <div class="login">
    <img class="logo" src="@/assets/images/logo.png" alt="亿目佳" />
    <div class="loginDiv">
      <h4>亿目佳眼健康数字疗法平台</h4>
      <h5>视功能训练 有效预防近视 控制或延缓度数增长</h5>
      <p></p>
      <a-form
        :model="formData"
        name="basic"
        autocomplete="off"
        @finish="onFinish"
        @finishFailed="onFinishFailed"
        class="form"
        v-if="isForm"
      >
        <a-form-item
          name="userName"
          :rules="[{ required: true, message: '请输入账号!' }]"
        >
          <a-input
            class="input"
            v-model:value="formData.userName"
            placeholder="请输入账号"
          />
        </a-form-item>

        <a-form-item
          name="password"
          :rules="[{ required: true, message: '请输入密码!' }]"
        >
          <a-input-password
            class="input"
            v-model:value="formData.password"
            placeholder="请输入密码"
          />
        </a-form-item>

        <a-form-item class="rememberPwd" name="remember">
          <a-checkbox v-model:checked="formData.remember">记住密码</a-checkbox>
          <span @click="forget">忘记密码</span>
        </a-form-item>

        <a-form-item>
          <a-button class="btn" html-type="submit">登录</a-button>
        </a-form-item>
      </a-form>

      <div class="codeDiv" v-else>
        <div>请用微信扫描二维码登录</div>
        <img :src="codeUrl" alt="公众号" />
      </div>

      <!-- <div class="btnDiv">
        <span @click="codeLogin" :class="selected ? '' : 'active'">
          扫码登录
        </span>
        <span @click="userLogin" :class="selected ? 'active' : ''">
          账号登录
        </span>
      </div> -->
    </div>

    <div class="footer">
      <div>技术支持：成都慧享众和科技有限公司 护眼热线：18190721582</div>
      <div>
        版权所有 成都慧享众和科技有限公司
        <span @click="open"> 蜀ICP备2021021981号-2 </span>
      </div>
    </div>

    <div v-if="visible" class="modalDiv">
      <div>
        <p>{{ modalHint }}</p>
        <button @click="handleOk">好的</button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import api from "@/apis/api.js";
export default defineComponent({
  name: "",
  components: {},
  props: {},
  emits: [],
  setup() {
    const router = useRouter();
    const isForm = ref(true);
    const selected = ref(true);
    const modalHint = ref("");
    const formData = reactive({
      userName: localStorage.getItem("userName")
        ? localStorage.getItem("userName")
        : "",
      password: localStorage.getItem("password")
        ? localStorage.getItem("password")
        : "",
      remember: true,
    });
    const visible = ref(false);
    const codeUrl = ref("");
    let timer = null;
    let codeTimer = null;
    let id = {
      sceneId: "",
    };
    for (let i = 0; i < 32; i++) {
      id.sceneId += Math.floor(Math.random() * 10);
    }

    const handleOk = (e) => {
      visible.value = false;
    };
    const staticAccount = [
      {
        userName: "YMJ001",
        password: "000000",
      },
    ];
    const testAccount = (values) => {
      return staticAccount.some((item) => {
        return (
          item.userName === values.userName && item.password === values.password
        );
      });
    };
    const onFinish = (values) => {
      if (testAccount(values)) {
        // 检测是否为本地静态账号登录
        router.push("./spreadIndex");
        if (values.remember) {
          localStorage.setItem("userName", values.userName);
          localStorage.setItem("password", values.password);
        } else {
          localStorage.clear();
        }
        return;
      }
      api.login(values).then((res) => {
        if (res.code === 200) {
          sessionStorage.setItem("token", res.msg);
          router.push("./home");
          if (values.remember) {
            localStorage.setItem("userName", values.userName);
            localStorage.setItem("password", values.password);
          } else {
            localStorage.clear();
          }
        } else {
          visible.value = true;
          modalHint.value = res.msg;
        }
      });
    };

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    const forget = () => {
      visible.value = true;
      modalHint.value = "请到亿目佳公众号-个人中心-眼健康卡”查看您的账号密码!";
    };

    const getResult = () => {
      api.getRes(id).then((res) => {
        if (res.code === 200) {
          sessionStorage.setItem("token", res.msg);
          clearInterval(timer);
          clearTimeout(codeTimer);
          router.push("./home");
        } else if (res.code === 10003) {
          return;
        } else {
          visible.value = true;
          modalHint.value = res.msg;
          clearInterval(timer);
          clearTimeout(codeTimer);
        }
      });
    };

    const codeExpired = () => {
      codeTimer = setTimeout(() => {
        clearInterval(timer);
        codeLogin();
      }, 1000 * 60 * 5);
    };

    const codeLogin = () => {
      api.qrcode(id).then((res) => {
        if (res.code == 200) {
          codeUrl.value = res.msg;
          selected.value = false;
          isForm.value = false;
          codeExpired();
          setTimeout(() => {
            timer = setInterval(() => {
              getResult();
            }, 1000);
          }, 2000);
        }
      });
    };

    const userLogin = () => {
      clearInterval(timer);
      clearTimeout(codeTimer);
      selected.value = true;
      isForm.value = true;
    };

    const open = () => {
      window.open("https://beian.miit.gov.cn/", "_blank");
    };

    return {
      formData,
      onFinish,
      onFinishFailed,
      isForm,
      selected,
      codeLogin,
      userLogin,
      visible,
      handleOk,
      modalHint,
      forget,
      codeUrl,
      open,
    };
  },
});
</script>
<style lang="less" scoped>
.login {
  > .modalDiv {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    > div {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -150px;
      margin-left: -250px;
      width: 500px;
      height: 300px;
      background: #fff;
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > p {
        font-size: 20px;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
      }
      > button {
        width: 370px;
        height: 50px;
        background: #06502e;
        line-height: 50px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        border: none;
      }
    }
  }
  > .footer {
    position: absolute;
    left: 0;
    bottom: 25px;
    width: 100%;
    text-align: center;
    color: #595757;
    font-size: 16px;
    > div {
      > span:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  > .loginDiv {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -288px;
    margin-left: -364px;
    width: 728px;
    height: 577px;
    text-align: center;
    background: #fcfdfe99;
    > h4 {
      font-size: 43px;
      font-weight: bold;
      color: #06502e;
      margin: 40px 0 18px;
    }
    > h5 {
      font-size: 19px;
      font-weight: 400;
      color: #666666;
      margin-bottom: 23px;
    }
    > p {
      margin: 0 auto;
      width: 104px;
      height: 2px;
      background: #06502e;
    }
    > .form {
      margin-top: 50px;
      text-align: center;
      .input {
        width: 409px;
        height: 53px;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        font-size: 16px;
        font-weight: 400;
        color: #595757;
        line-height: 53px;
      }
      ::v-deep(.ant-checkbox-checked .ant-checkbox-inner) {
        background-color: #06502e;
        border-color: #06502e;
      }
      ::v-deep(.ant-checkbox-checked::after, ) {
        border: 1px solid #06502e;
      }
      ::v-deep(.ant-checkbox-wrapper:hover
          .ant-checkbox-inner, .ant-checkbox:hover
          .ant-checkbox-inner, .ant-checkbox-input:focus
          + .ant-checkbox-inner) {
        border-color: #06502e;
      }
      .rememberPwd {
        span:hover {
          cursor: pointer;
        }
        margin: 0 auto 10px;
        width: 409px;
        ::v-deep(.ant-form-item-control-input-content) {
          display: flex;
          justify-content: space-between;
        }
      }
      .btn {
        width: 409px;
        height: 53px;
        background: #06502e;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 53px;
        border: none;
      }
    }
    > .codeDiv {
      margin-top: 49px;
      > div {
        font-size: 16px;
        font-weight: bold;
        color: #595757;
      }
      > img {
        width: 244px;
        height: 244px;
        margin: 6px 0 12px;
      }
    }
    > .btnDiv {
      > span {
        display: inline-block;
        width: 175px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #595757;
      }
      > span:hover {
        cursor: pointer;
      }
      > .active {
        color: #06502e;
      }
    }
  }
  > .logo {
    margin: 30px 40px;
  }
  position: relative;
  width: 100%;
  height: 100vh;
  background: url(../assets/images/bg.png) center/cover;
}
</style>